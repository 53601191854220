import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P, Ul } from 'stile-shared/src/components/2020/common/Primitives';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function EffectiveStilistsPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/effective-stilists/cover_effective_stilists.png"
            alt="A software engineer wearing headphones working on a laptop"
          />
        }
      />

      <TextBlock space={theme.space.l}>
        <P>
          A little while ago, we went through an exercise in thinking about the behaviours and
          qualities that make people truly <em>outstanding</em> contributors to Stile’s team. No one
          person at Stile is all of these things – they’re aspirational as we’re constantly learning
          and growing - and in no particular order.
        </P>

        <Ul>
          <li>
            <strong>Teamwork: </strong>
            You’re humble, honest and put the needs of others above your own more often than not.
            You’re not afraid to ask for help when you need it. You always assume positive intent,
            and clarify, rather than assuming the worst and staying silent. You understand and
            respect all perspectives on situations and decisions that you are involved with. You
            often achieve change by winning the hearts and minds of everyone involved and turning
            them into allies.
          </li>
          <li>
            <strong>Customer Focus: </strong>
            You’re committed to making sure anyone that interacts with Stile has a surprisingly
            delightful experience.
          </li>
          <li>
            <strong>Getting Stuff Done: </strong>
            You accomplish amazing amounts of important work. You make time to reflect on your
            priorities, and those of the business, to decide what is truly important now. You have a
            bias towards action. You make well-reasoned decisions quickly, and get things done. You
            do whatever it takes to get the job done, without compromising integrity. You seek to
            understand what should be done well now, and what can be improved later. You have a
            strong understanding of what you are, and aren’t, trying to achieve.
          </li>
          <li>
            <strong>Self Reflection: </strong>
            You make time to reflect on your own skills and behaviours and actively look for ways to
            improve on what you are doing. You are open to hearing and eager to respond to critical
            evaluation of your performance. When you err, you admit your mistake fully and quickly.
          </li>
          <li>
            <strong>Argumentation: </strong>
            You believe diversity of opinion and ideas ultimately helps us achieve better outcomes.
            You respectfully listen to and reflect on the points of view of others, but are also
            respectfully willing to present your view, even if controversial. You’re prepared to
            challenge standing assumptions and suggest better approaches.
          </li>
          <li>
            <strong>Transparency: </strong>You are transparent and honest by default. You prefer to
            share ideas and information with the wider team.
          </li>
          <li>
            <strong>Initiative: </strong>
            You take the lead to ensure we’re delivering on our mission. You don’t wait to be asked
            to do something, and you identify opportunities to delight. Never trying to do the bare
            minimum, you always seek to exceed expectations, to deliver work that is better than
            what was asked for.
          </li>
          <li>
            <strong>High Standards: </strong>
            Your work is of excellent quality, and always embodies wise trade-offs.
          </li>
          <li>
            <strong>Drive: </strong>
            You’ve got a positive attitude. You seek to avoid complaining and prefer to present
            solutions rather than problems.
          </li>
          <li>
            <strong>Creativity: </strong>You generate useful novel ideas, and you’re always
            considering how we could deliver meaningfully better education.
          </li>
          <li>
            <strong>Fast Learner: </strong>
            You’re a fast and eager learner.
          </li>
          <li>
            <strong>Knowledge: </strong>
            You are broadly knowledgeable about technology, pedagogy, science and the world around
            us. You seek to deeply understand schools, teachers, students, our competitors and the
            education sector at large. You seek to understand how what we do fits into that picture.
          </li>
        </Ul>
      </TextBlock>

      <NextPageBlock path="/who-we-are/hiring-process/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default EffectiveStilistsPage;
